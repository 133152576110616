import Vue from 'vue'
import VueI18n from 'vue-i18n'
import messages from '@/plugins/i18n/locales/fr'
import messagesEn from '@/plugins/i18n/locales/en'

Vue.use(VueI18n)

export const i18n = new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  locales: [
    {
      title: 'English',
      img: require('@/assets/images/flags/en.png'),
      locale: 'en',
    },
    {
      title: 'French',
      img: require('@/assets/images/flags/fr.png'),
      locale: 'fr',
    },
  ], // set locale

  messages: {
    fr: messages,
    en: messagesEn,
  }, // set locale messages
})

const loadedLanguages = ['fr', 'en'] // our default language that is preloaded

function setI18nLanguage(lang) {
  i18n.locale = lang

  return lang
}

export function loadLanguageAsync(lang) {
  // If the same language
  if (i18n.locale === lang) {
    return Promise.resolve(setI18nLanguage(lang))
  }

  // If the language was already loaded
  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang))
  }

  // If the language hasn't been loaded yet
  /* eslint-disable prefer-template */
  return import(/* webpackChunkName: "lang-[request]" */ '@/plugins/i18n/locales/' + lang + '.js').then(msgs => {
    i18n.setLocaleMessage(lang, msgs.default)
    loadedLanguages.push(lang)

    return setI18nLanguage(lang)
  })
  /* eslint-enable */
}
