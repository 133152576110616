import {
  mdiBookAccountOutline,
  mdiCart,
  mdiBicycleBasket,
  mdiShopping,
  mdiMotorbike,
  mdiFood,
  mdiMenu,
  mdiStore,
  mdiClipboardList,
  mdiAccountTie,
  mdiAccountTieVoice,
  mdiAccountGroup,
  mdiLibrary,
  mdiViewList,
  mdiApps,
  mdiBell,
} from '@mdi/js'

export default [
  {
    subheader: 'Management',
  },
  {
    title: 'Orders',
    icon: mdiShopping,
    to: 'orders',
    resource: 'Order',
    action: 'read',

    // badge: '3',
    // badgeColor: 'warning',
  },
  {
    title: 'Carts',
    icon: mdiCart,
    to: 'carts',
    resource: 'Cart',
    action: 'read',

    // badge: '3',
    // badgeColor: 'warning',
  },


  {
    title: 'Businesses',
    icon: mdiStore,
    to: 'shops',
    resource: 'Shop',
    action: 'read',

    // badge: '3',
    // badgeColor: 'warning',
  },
  {
    title: 'Products',
    icon: mdiViewList,
    to: 'products',
    resource: 'Product',
    action: 'read',

    // badge: '3',
    // badgeColor: 'warning',
  },
  {
    title: 'Meals',
    icon: mdiFood,
    to: 'meals',
    resource: 'Meal',
    action: 'read',

    // badge: '3',
    // badgeColor: 'warning',
  },
  {
    title: 'Foods',
    icon: mdiClipboardList,
    to: 'foods',
    resource: 'Food',
    action: 'read',

    // badge: '3',
    // badgeColor: 'warning',
  },
  {
    title: 'Apps',
    icon: mdiApps,
    to: 'apps',
    resource: 'App',
    action: 'read',

    // badge: '3',
    // badgeColor: 'warning',
  },
  {
    title: 'Packages',
    icon: mdiBookAccountOutline,
    to: 'packages',
    resource: 'Package',
    action: 'read',

    // badge: '3',
    // badgeColor: 'warning',
  },
  {
    title: 'Subscriptions',
    icon: mdiCart,
    to: 'subscriptions',
    resource: 'Subscription',
    action: 'read',

    // badge: '3',
    // badgeColor: 'warning',
  },
  {
    title: 'Rides',
    icon: mdiMotorbike,
    to: 'rides',
    resource: 'Ride',
    action: 'read',

    // badge: '3',
    // badgeColor: 'warning',
  },
  {
    title: 'Agents',
    icon: mdiAccountTie,
    to: 'agents',
    resource: 'Agent',
    action: 'read',

    // badge: '3',
    // badgeColor: 'warning',
  },
  {
    title: 'Managers',
    icon: mdiAccountGroup,
    to: 'managers',
    resource: 'Manager',
    action: 'read',
  },
  {
    title: 'Campaigns',
    icon: mdiBell,
    to: 'campaigns',
    resource: 'Campaign',
    action: 'read',
  },
  {
    title: 'Referrals',
    icon: mdiAccountTie,
    to: 'referrals',
    resource: 'Referral',
    action: 'read',
  },
]
