const apps = [
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/apps/user/user-view/UserView.vue'),
    meta: {
      layout: 'content',
      resource: 'Public',
    },
  },
  {
    path: '/users/:id',
    name: 'user-view',
    component: () => import('@/views/apps/user/user-view/UserView.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/users',
    name: 'users',
    component: () => import('@/views/apps/user/user-list/Users.vue'),
    meta: {
      layout: 'content',
      resource: 'User',
    },
  },
  {
    path: '/types',
    name: 'types',
    component: () => import('@/views/apps/types/Types.vue'),
    meta: {
      layout: 'content',
      resource: 'Type',
    },
  },
  {
    path: '/transportations',
    name: 'transportations',
    component: () => import('@/views/apps/transportations/Transportations.vue'),
    meta: {
      layout: 'content',
      resource: 'Transportation',
    },
  },
  {
    path: '/services',
    name: 'services',
    component: () => import('@/views/apps/services/Services.vue'),
    meta: {
      layout: 'content',
      resource: 'Service',
    },
  },
  {
    path: '/cities',
    name: 'cities',
    component: () => import('@/views/apps/cities/Cities.vue'),
    meta: {
      layout: 'content',
      resource: 'City',
    },
  },
  {
    path: '/divisions',
    name: 'divisions',
    component: () => import('@/views/apps/divisions/Divisions.vue'),
    meta: {
      layout: 'content',
      resource: 'Division',
    },
  },
  {
    path: '/quarters',
    name: 'quarters',
    component: () => import('@/views/apps/quarters/Quarters.vue'),
    meta: {
      layout: 'content',
      resource: 'Quarter',
    },
  },
  {
    path: '/tariffs',
    name: 'tariffs',
    component: () => import('@/views/apps/tariffs/Tariffs.vue'),
    meta: {
      layout: 'content',
      resource: 'Tariff',
    },
  },
  {
    path: '/roles',
    name: 'roles',
    component: () => import('@/views/apps/role/Roles.vue'),
    meta: {
      layout: 'content',
      resource: 'Role',
    },
  },
  {
    path: '/permissions',
    name: 'permissions',
    component: () => import('@/views/apps/permission/Permissions.vue'),
    meta: {
      layout: 'content',
      resource: 'Permission',
    },
  },
  {
    path: '/sources',
    name: 'sources',
    component: () => import('@/views/apps/sources/Sources.vue'),
    meta: {
      layout: 'content',
      resource: 'Configuration',
    },
  },
  {
    path: '/colors',
    name: 'colors',
    component: () => import('@/views/apps/colors/Colors.vue'),
    meta: {
      layout: 'content',
      resource: 'Configuration',
    },
  },
  {
    path: '/activities',
    name: 'activities',
    component: () => import('@/views/apps/activities/Activities'),
    meta: {
      layout: 'content',
      resource: 'Configuration',
    },
  },
  {
    path: '/payments',
    name: 'payments',
    component: () => import('@/views/apps/payments/Payments.vue'),
    meta: {
      layout: 'content',
      resource: 'Configuration',
    },
  },
  {
    path: '/credits',
    name: 'credits',
    component: () => import('@/views/apps/credits/Credits.vue'),
    meta: {
      layout: 'content',
      resource: 'Configuration',
    },
  },
  {
    path: '/withdraws',
    name: 'withdraws',
    component: () => import('@/views/apps/withdraws/Withdraws.vue'),
    meta: {
      layout: 'content',
      resource: 'Configuration',
    },
  },
  {
    path: '/statuses',
    name: 'statuses',
    component: () => import('@/views/apps/status/Statuses.vue'),
    meta: {
      layout: 'content',
      resource: 'Configuration',
    },
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import('@/views/apps/settings/Settings.vue'),
    meta: {
      layout: 'content',
      resource: 'Configuration',
    },
  },
  {
    path: '/imports',
    name: 'imports',
    component: () => import('@/views/apps/settings/Imports.vue'),
    meta: {
      layout: 'content',
      resource: 'Configuration',
    },
  },
  {
    path: '/configurations',
    name: 'configurations',
    component: () => import('@/views/apps/settings/Configurations.vue'),
    meta: {
      layout: 'content',
      resource: 'Configuration',
    },
  },
  {
    path: '/tarifications',
    name: 'tarifications',
    component: () => import('@/views/apps/tarifications/Tarifications.vue'),
    meta: {
      layout: 'content',
      resource: 'Configuration',

    },
  },
  {
    path: '/formats',
    name: 'formats',
    component: () => import('@/views/apps/formats/Formats.vue'),
    meta: {
      layout: 'content',
      resource: 'Configuration',
    },
  },
  {
    path: '/genres',
    name: 'genres',
    component: () => import('@/views/apps/genres/Genres.vue'),
    meta: {
      layout: 'content',
      resource: 'Configuration',
    },
  },
  {
    path: '/origins',
    name: 'origins',
    component: () => import('@/views/apps/origins/Origins.vue'),
    meta: {
      layout: 'content',
      resource: 'Configuration',
    },
  },
  {
    path: '/countries',
    name: 'countries',
    component: () => import('@/views/apps/countries/Countries.vue'),
    meta: {
      layout: 'content',
      resource: 'Configuration',
    },
  },
  {
    path: '/tasks',
    name: 'tasks',
    component: () => import('@/views/apps/tasks/Tasks.vue'),
    meta: {
      layout: 'content',
      resource: 'Tasks',
    },
  },
  {
    path: '/isbns',
    name: 'isbns',
    component: () => import('@/views/apps/isbns/Isbns.vue'),
    meta: {
      layout: 'content',
      resource: 'isbns',
    },
  },
]

export default apps
