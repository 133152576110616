const apps = [
  {
    path: '/authors/:id',
    name: 'author-view',
    component: () => import('@/views/apps/author/author-view/AuthorView.vue'),
    meta: {
      layout: 'content',
      resource: 'Author',
    },
  },
  {
    path: '/authors',
    name: 'authors',
    component: () => import('@/views/apps/author/author-list/Authors.vue'),
    meta: {
      layout: 'content',
      resource: 'Author',
    },
  },
  {
    path: '/adresses',
    name: 'address',
    component: () => import('@/views/apps/adresses/Adresses.vue'),
    meta: {
      layout: 'content',
      resource: 'Address',
    },
  },
  {
    path: '/packages/:id',
    name: 'packages-view',
    component: () => import('@/views/apps/author/author-view/AuthorView.vue'),
    meta: {
      layout: 'content',
      resource: 'Package',
    },
  },
  {
    path: '/packages',
    name: 'packages',
    component: () => import('@/views/apps/packages/Packages.vue'),
    meta: {
      layout: 'content',
      resource: 'Package',
    },
  },
  {
    path: '/subscriptions',
    name: 'subscriptions',
    component: () => import('@/views/apps/subscriptions/Subscriptions.vue'),
    meta: {
      layout: 'content',
      resource: 'Subscription',
    },
  },
  {
    path: '/rides',
    name: 'rides',
    component: () => import('@/views/apps/rides/Rides.vue'),
    meta: {
      layout: 'content',
      resource: 'Ride',
    },
  },
  {
    path: '/shops',
    name: 'shops',
    component: () => import('@/views/apps/shops/Shops.vue'),
    meta: {
      layout: 'content',
      resource: 'Shop',
    },
  },
  {
    path: '/foods',
    name: 'foods',
    component: () => import('@/views/apps/foods/Foods.vue'),
    meta: {
      layout: 'content',
      resource: 'Food',
    },
  },
  {
    path: '/apps',
    name: 'apps',
    component: () => import('@/views/apps/apps/Apps.vue'),
    meta: {
      layout: 'content',
      resource: 'App',
    },
  },
  {
    path: '/meals',
    name: 'meals',
    component: () => import('@/views/apps/meals/Meals.vue'),
    meta: {
      layout: 'content',
      resource: 'Meal',
    },
  },
  {
    path: '/products',
    name: 'products',
    component: () => import('@/views/apps/products/Products.vue'),
    meta: {
      layout: 'content',
      resource: 'Product',
    },
  },
  {
    path: '/payments',
    name: 'payments',
    component: () => import('@/views/apps/publications/Payments.vue'),
    meta: {
      layout: 'content',
      resource: 'Paiment',
    },
  },
  {
    path: '/carts',
    name: 'carts',
    component: () => import('@/views/apps/carts/Carts.vue'),
    meta: {
      layout: 'content',
      resource: 'Cart',
    },
  },
  {
    path: '/orders',
    name: 'orders',
    component: () => import('@/views/apps/orders/Orders.vue'),
    meta: {
      layout: 'content',
      resource: 'Order',
    },
  },
  {
    path: '/agents',
    name: 'agents',
    component: () => import('@/views/apps/agents/Agents.vue'),
    meta: {
      layout: 'content',
      resource: 'Agent',
    },
  },
  {
    path: '/managers',
    name: 'managers',
    component: () => import('@/views/apps/managers/Managers.vue'),
    meta: {
      layout: 'content',
      resource: 'Manager',
    },
  },
  {
    path: '/campaigns',
    name: 'campaigns',
    component: () => import('@/views/apps/campaigns/Campaigns.vue'),
    meta: {
      layout: 'content',
      resource: 'Campaign',
    },
  },
  {
    path: '/referrals',
    name: 'referrals',
    component: () => import('@/views/apps/referrals/Referrals.vue'),
    meta: {
      layout: 'content',
      resource: 'Referral',
    },
  },
]

export default apps
