import {
  mdiAccountCheckOutline, mdiAccountCogOutline,
  mdiAccountOutline,
  mdiCity,
} from '@mdi/js'

export default [
  {
    subheader: 'CONFIGURATIONS',
  },
  {
    title: 'Data',
    icon: mdiAccountOutline,
    /*resource: 'Configuration',*/
    /*action: 'read',*/
    children: [
      {
        title: 'Activities',
        to: 'activities',
        resource: 'Activities',
        action: 'read',
      },
      {
        title: 'Payments',
        to: 'payments',
        resource: 'Payment',
        action: 'read',
      },
      {
        title: 'Credits',
        to: 'credits',
        resource: 'Credit',
        action: 'read',
      },
      {
        title: 'Withdraws',
        to: 'withdraws',
        resource: 'Withdraw',
        action: 'read',
      },
      {
        title: 'Services',
        to: 'services',
        resource: 'Service',
        action: 'read',
      },
      {
        title: 'Means of transportations',
        to: 'transportations',
        resource: 'Transportation',
        action: 'read',
      },
      {
        title: 'Types',
        to: 'types',
        resource: 'Type',
        action: 'read',
      },
    ],
  },
  {
    title: 'Location',
    icon: mdiCity,
    children: [
      {
        title: 'Cities',
        to: 'cities',
        resource: 'City',
        action: 'read',
      },
      {
        title: 'Divisions',
        to: 'divisions',
        resource: 'Division',
        action: 'read',
      },
      {
        title: 'Quarters',
        to: 'quarters',
        resource: 'Quarter',
        action: 'read',
      },
      {
        title: 'Tariffs',
        to: 'tariffs',
        resource: 'Tariff',
        action: 'read',
      },
    ],
  },
  {
    title: 'Users',
    icon: mdiAccountOutline,
    to: 'users',
    resource: 'User',
    action: 'read',
  },
  {
    title: 'Roles',
    icon: mdiAccountCogOutline,
    to: 'roles',
    resource: 'Role',
    action: 'read',
  },
  {
    title: 'Permissions',
    icon: mdiAccountCheckOutline,
    to: 'permissions',
    resource: 'Permission',
    action: 'read',
  },
]
